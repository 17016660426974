<template>
  <CCol cols="12" xl="12" lg="12" md="12" sm="12"> 
    <CCard class="mb-0 events moments">
      <CCardHeader class="pb-0">
        <CRow>
          <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
            <span>{{$t('sm.Latest_sparks')}}</span>
          </CCol>          
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CRow class="moment_list">
          <CCol cols="12" lg="12" md="12" :class="{'pt-0 pb-0' : !momentsLoading}">
            <div class="card_container" id="masonryWall">
              <loadingSpinner mode="auto" v-if="momentsLoading" :content="$t('common.Loading')"/>

              <vue-masonry-wall v-else-if="!momentsLoading && moments.length > 0" :items="moments" :options="{width: 400}" :ssr="{columns: 2}" class="pt-0 pb-0">
                <template #default="{item}">                  
                  <loyaltyMomentCard v-if="item"
                                     :modules="modules"
                                     :platformPermissions="platformPermissions"
                                     :momentData="item" 
                                     :showEditButton="item.moment_scheduled && item.moment_editable && checkPermission('loyalty.moments.edit')"
                                     :showDeleteButton="item.moment_scheduled && item.moment_deletable && checkPermission('loyalty.moments.delete')"
                                     :showInsights="true">
                  </loyaltyMomentCard>
                </template>                
              </vue-masonry-wall>             
            </div>          
          </CCol>                  
        </CRow>
      </CCardBody>
    </CCard>

    <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('sm.Remove_scheduled_spark', { moment_name : confirmModalData.name})}} ({{confirmModalData.scheduled_for | moment("DD-MM-YYYY HH:mm")}})
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="m-0" v-html="$t('sm.Are_you_sure_to_delete_scheduled_spark')"></p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deleteLoyaltyMoment(confirmModalData.loyalty_moment_id_external);"><i class="fas fa-trash mr-1"/>{{$t('Delete')}}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </CCol>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import VueMasonryWall from "vue-masonry-wall";

import userProfilePopover from '@/components/common/userProfilePopover.vue';
import loyaltyMomentCard from '@/components/loyalty/loyaltyMomentCard.vue';

export default {
  name: 'loyaltyMoments',
  props: ['platformPermissions', 'platformRoles', 'modules'],
  components: {
    loadingSpinner,
    VueMasonryWall,
    userProfilePopover,
    loyaltyMomentCard
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      company: {},
      moments: [],
      momentsLoading: false,
      momentsLoaded: false,
      confirmModal: false,
      confirmModalData: {}
    }
  },
  methods: {    
    getMoments() {
      // Set the params
      let params = {};
      params.senders = [];
      params.receivers = [];
      params.date_from = null;
      params.date_to = null;
      // Start the loader
      this.momentsLoading = true;
      // Set default moments array
      this.moments = [];
      // Get the Moments
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moments/latest', params)
      .then(res => {                
        let moments = res.data.data;
        // Add the Moments to the Moments array
        for (let m = 0; m < moments.length; m++) {     
          this.moments.push(moments[m]);    
        }
        // Stop the loader
        this.momentsLoading = false;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMomentDetails(momentIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + momentIdExternal)
      .then(res => {
        let updatedMoment = res.data.data;
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_moment_id_external == updatedMoment.loyalty_moment_id_external);
        // Update the data    
        this.moments[momentIndex].points = updatedMoment.points;          
        this.moments[momentIndex].total_recipients = updatedMoment.total_recipients;
        this.moments[momentIndex].total_filters = updatedMoment.total_filters;
        this.moments[momentIndex].scheduled_for = updatedMoment.scheduled_for;
        this.moments[momentIndex].sent = updatedMoment.sent;
        this.moments[momentIndex].insights = updatedMoment.insights;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    deleteLoyaltyMoment(momentIdExternal) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/moment/' + momentIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('sm.Scheduled_spark_deleted'), type: 'is-success', duration: 2000 });
        // Get the moment index
        let momentIndex = this.moments.findIndex(x => x.loyalty_moment_id_external == momentIdExternal);
        // Remove the deleted Moment from the moments array
        this.moments.splice(momentIndex, 1);
        // Close the modal
        this.confirmModal = false;        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    }                 
  },
  mounted: function() {
    this.getMoments();

    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
        
    this.getCompanyDetails();   

    this.$bus.$on('update_loyalty_moments', () => {
      this.getMoments();
    });

    this.$bus.$on('update_loyalty_moment_details', (momentIdExternal) => {
      this.getMomentDetails(momentIdExternal);
    });

    this.$bus.$on('delete_loyalty_moment', (momentData) => {      
      this.confirmModalData = momentData;
      this.confirmModal = true;
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_loyalty_moments');
    this.$bus.$off('update_loyalty_moment_details');
    this.$bus.$off('delete_loyalty_moment');
  }  
}
</script>