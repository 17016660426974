<template>
  <CCard class="insights m-0">
    <CCardHeader v-if="showHeader" class="pb-0">
      <CRow>
        <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">              
          {{ $t('insights.Employee_points_updates2') }}
        </CCol>
        <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
          <div class="mt-1 mt-xl-0 d-inline-block align-top">
            <CButton class="ml-2 link" color="primary" @click="checkExportOptionWithinLicense('csv')">
              <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_csv') }}</span>
            </CButton>                
            <CButton class="ml-2 link" color="primary" @click="checkExportOptionWithinLicense('json');">
              <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
            </CButton>
          </div>               
        </CCol>  
      </CRow>
    </CCardHeader>
    <CCardBody class="p-0">
      <div v-if="employeePointsUpdatesLoading === true">           
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="null"/>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <CRow>
          <CCol cols="12" lg="12" class="pb-0">          
            <b-table class="data_table"
                    :data="employeePointsUpdates"
                    :striped="true"
                    :hoverable="true"
                    :mobile-cards="true"
                    :paginated="isPaginated"
                    :per-page="itemsPerPage"
                    :current-page.sync="currentPage"
                    :pagination-simple="isPaginationSimple"
                    :pagination-position="paginationPosition"
                    :default-sort-direction="defaultSortDirection"
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="Department/Location">
              
              <template slot-scope="props">
                <b-table-column field="employee" :label="$t('common.Employee')" :searchable="searchEnabled" :sortable="true" width="12.5%">
                  <div class="d-flex align-items-center">
                    <userProfilePopover v-if="props.row.user_is_system_account !== 'Y'" 
                                        ref="userProfilePopover"
                                        mode="user" 
                                        :userName="props.row.employee"
                                        :userProfileImage="props.row.employee_profile_image" 
                                        :userIdExternal="props.row.employee_user_id_external"
                                        :senderIdExternal="null"
                                        popoverPlacement="right"
                                        class="mr-2">
                    </userProfilePopover>
                    <div>
                      <b>{{props.row.employee}}</b>
                      <div class="meta mt-1">
                        <span>{{props.row.department}} - {{props.row.team}}</span>
                      </div>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="amount" :label="$t('common.Amount')" :searchable="false" :sortable="true" width="7.5%">                  
                  <div class="d-flex align-items-center justify-content-start points_update" v-bind:class="{ 'points_received' : props.row.change === 'increase', 'points_spend' : props.row.change === 'decrease' }">                  
                    <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-app/credits/' + environmentTag + '_credit.png/' + clientToken"/>                  
                    <div class="ml-1 d-flex align-items-center">
                      <span v-if="props.row.change === 'increase'">+</span>
                      <span v-if="props.row.change === 'decrease'">-</span>
                      <span>{{props.row.amount | abbr}}</span>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column field="spend_or_received_for" :label="$t('insights.Spend_received_for')" :searchable="searchEnabled" :sortable="true" width="20%">
                  <span>{{props.row.spend_or_received_for}}</span>
                  <div v-if="props.row.spend_or_received_for_subject" class="meta mt-1">
                    <span v-html="props.row.spend_or_received_for_subject"></span>
                  </div>
                </b-table-column>
                <b-table-column field="given_by_user" :label="$t('insights.Given_by_user')" :searchable="searchEnabled" :sortable="true" width="12.5%">
                  {{props.row.given_by_user}}
                </b-table-column>
                <b-table-column field="received_at" :label="$t('insights.Spend_received_at')" :searchable="searchEnabled" :sortable="true" width="12.5%">
                  {{props.row.received_at | moment("DD-MM-YYYY HH:mm")}}
                </b-table-column>                  
              </template>
              <template slot="empty">
                <div class="text-center">                
                  <span>{{ $t('insights.Statistics_not_found') }}</span>
                </div>                
              </template>                
            </b-table>
          </CCol>
        </CRow>
      </div>     
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'loyaltyPointsUpdates',
  props: ['platformPermissions', 'showHeader', 'itemsPerPage', 'productLicenses'],
  components: {
    loadingSpinner,
    userProfilePopover
  },
  data() {
    return {     
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,      
      employeePointsUpdates: [],
      employeePointsUpdatesLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      searchEnabled: false,
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      showInactive: false,
      searchEnabled: true
    }
  },
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  },  
  methods: {
    checkExportOptionWithinLicense(exportFormat) {
      let licenseTag = this.productLicenses.loyalty_license_tag;
      let licenseName = this.productLicenses.loyalty_license_name;
    
      if(['business', 'enterprise'].includes(licenseTag)) {        
        this.exportData(exportFormat);
      } else {
        this.openFeatureUnavailableModal({ license_name: licenseName });
      }
    },    
    getUserPoints () {
      // Start the loader
      this.employeePointsUpdatesLoading = true;
      // Get the updates      
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/points/employees/updates')
      .then(res => {
        this.employeePointsUpdates = res.data.data;
        // Enable the pagination if necessary
        (this.employeePointsUpdates.length > this.itemsPerPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Update the employeePointsUpdatesLoading value
        this.employeePointsUpdatesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    exportData(outputFormat){
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/points/employees/updates/export/' + outputFormat)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
        if (!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.URL.createObjectURL(new Blob([outputData]));  
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.URL.createObjectURL(new Blob([outputData]));
          }
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute based on the output format
          if(outputFormat === 'csv') {
            link.setAttribute('download', 'insights-user-points.csv');
          } else if(outputFormat === 'json') {
            link.setAttribute('download', 'insights-user-points.json');
          }
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-login.csv");
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]),"insights-audience-login.json");
          }
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  mounted: function() {   
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
    if(localStorage.getItem('environmentTag')) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.getUserPoints();

    this.$bus.$on('filter_updated', (e) => {
      this.getUserPoints();
    });    
  },
  beforeDestroy() {
    this.$bus.$off('filter_updated');
  }  
}
</script>
