<template>
  <div class="w-100 d-flex flex-wrap insights">
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('sm.Sparks_sent_last_month')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <Count v-else mode="single_number" :count="metrics.moments_sent" :countTitle="null" :countIcon="null"/>         
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="3" xl="3" lg="3" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('sm.Unique_receivers_last_month')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>          
          <Count v-else mode="single_number" :count="metrics.unique_recipients" :countTitle="null" :countIcon="null"/>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol cols="6" xl="6" lg="6" md="12" sm="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
              {{$t('sm.Most_used_subjects')}}
            </CCol>       
          </CRow>       
        </CCardHeader>
        <CCardBody>
          <div v-if="loadingData" class="h-100 d-flex align-items-center">
            <loadingSpinner mode="auto" :content="null"/>
          </div>
          <HighlightStage v-else 
                          :stageData="metrics.most_used_subjects" 
                          :scoreValue="'usage_count'"
                          :nameValue="'tag'"
                          :idValue="null"
                          :showDecimals="false"
                          :navigateTo="null">
          </HighlightStage>
        </CCardBody>
      </CCard>
    </CCol>        
  </div>  
</template>


<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import HighlightStage from '@/components/insights/common/HighlightStage.vue';
import Count from '@/components/insights/common/Count.vue';

export default {
  name: 'MeasureMetrics',  
  components: {
    loadingSpinner,
    HighlightStage,
    Count
  }, 
  data() {
    return {
      loadingData: false,
      metrics: {
        subjects: [],
        moments_sent: 0,
        unique_recipients: 0       
      }    
    }
  },
  methods: {
    getMomentMetrics() {
      // Start the loader
      this.loadingData = true;
      // Get the metrics
      axios.get(process.env.VUE_APP_API_URL + '/v1/insights/loyalty/moments')      
      .then(res => {
        this.metrics = res.data.data;
        // Close the loader
        this.loadingData = false;                      
      })
      .catch(err => {
        console.error(err); 
      });       
    }
  },
  mounted: function() {         
    this.getMomentMetrics();
  }
}
</script>